export function createCustomerInfoTable(worksheet, organization, stations) {
  let customerInfoBody = [
    ['Customer', organization],
    ['Station Name', stations],
    ['Responsible Engineer Obj', ''],
    ['Application CSM', ''],
    ['Project Manager', ''],
  ];

  worksheet.addRows(customerInfoBody);
  // Add border to each cell in the customer info table
  customerInfoBody.forEach((row, rowIndex) => {
    row.forEach((cell, colIndex) => {
      const cellRef = worksheet.getCell(rowIndex + 1, colIndex + 1);
      cellRef.style = {
        border: {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        },
        alignment: { horizontal: 'left', vertical: 'top', wrapText: true },
      };
    });
  });

  worksheet.mergeCells(1, 2, 1, 3);
  worksheet.mergeCells(2, 2, 2, 3);
  worksheet.mergeCells(3, 2, 3, 3);
  worksheet.mergeCells(4, 2, 4, 3);
  worksheet.mergeCells(5, 2, 5, 3);
}

export function createProcessDescriptionTable(worksheet) {
  const processDescriptionHeader = worksheet.getCell('E1');
  processDescriptionHeader.value = 'Process Descriptions';
  processDescriptionHeader.style = {
    fill: {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFD9EAD3' }, // Light Green Background
    },
    alignment: { horizontal: 'left', vertical: 'top' },
    border: {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    },
  };
  worksheet.mergeCells('E1:G1');

  const processDescriptionCell = worksheet.getCell('E2');
  processDescriptionCell.value = 'Your process description goes here...';
  processDescriptionCell.style = {
    fill: {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFD1EFFA' }, // Light Blue Background
    },
    alignment: { horizontal: 'left', vertical: 'top' },
    border: {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    },
  };
  worksheet.mergeCells('E2:G10');
}

export function createCustomerNoteTable(worksheet){
    const CNHeader = worksheet.getCell('A6');
    CNHeader.value = 'Customer Notes:';
    CNHeader.style = {
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFD9EAD3' }, // Light Green Background
      },
      alignment: { horizontal: 'left', vertical: 'top' },
      border: {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      },
    };
    worksheet.mergeCells('A6:C6');

    const CNDescription = worksheet.getCell('A7');
    worksheet.mergeCells('A7:C12');
    CNDescription.value = '';
    CNDescription.style = {
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFD1EFFA' }, // Light Blue Background
      },
      alignment: { horizontal: 'left', vertical: 'top', wrapText: true },
      border: {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      },
    };
  }
    
  export function createModelPerformanceTable(worksheet){
    const modelPerformanceHeader = worksheet.getCell('M1');
    modelPerformanceHeader.value = 'Model Performance';
    modelPerformanceHeader.style = {
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFD9EAD3' }, // Light Green Background
      },
      alignment: { horizontal: 'center', vertical: 'top' },
      border: {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      },
    };
    worksheet.mergeCells('M1:O1');

    let modelPerformanceHeaders = [
      { name: 'Model Type and Version', width: 15 },
      { name: 'Score', width: 15 },
      { name: 'Model Notes', width: 25 },
    ];

    modelPerformanceHeaders.forEach((header, index) => {
      worksheet.getColumn(13 + index).width = header.width; // Adjust for column M (13th column)
    });

    let modelPerformanceBody = [
      ['', '', ''],
      ['', '', ''],
      ['', '', ''],
      ['', '', ''],
      ['', '', ''],
    ];
    worksheet.addTable({
      name: 'ModelReferenceTable',
      ref: 'M2',
      columns: modelPerformanceHeaders.map((header) => ({
        name: header.name,
      })),
      rows: modelPerformanceBody,
    });

    // Style each cell in the body
    modelPerformanceBody.forEach((data, i) => {
      worksheet
        .getRow(3 + i) // Rows starting from 3 (because M1 is the header)
        .eachCell({ includeEmpty: false }, function (cell) {
          worksheet.getCell(cell._value.model.address).style = {
            border: {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' },
            },
            alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
          };
        });
    });

    modelPerformanceHeaders.forEach((header, index) => {
      const cell = worksheet.getCell(2, 13 + index); // Headers are on row 2 starting from column M (13th column)
      cell.style = {
        fill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'D1EFFA' },
          bgColor: { argb: 'D1EFFA' },
        },
        border: {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        },
        alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
      };
    });
  }

  export function createPeriodicAncillary(worksheet, initiateRow){
    let periodicActivitiesHeaders = [
      { name: 'Periodic Ancillary Activities', width: 25 },
      { name: 'Rough Period', width: 25 },
      { name: 'nothing' },
      { name: 'Focus', width: 15 },
      { name: 'Track' },
      { name: 'Notes' },
    ];

    // periodicActivitiesHeaders.forEach((header, index) => {
    //   worksheet.getColumn(index + 1).width = header.width; // Adjust to 1-based index
    // });

    let periodicActivitiesBody = [
      [
        '',
        '',
        '',
        '',
        '',
        '',
      ],
      [
        '',
        '',
        '',
        '',
        '',
        '',
      ],
      [
        '',
        '',
        '',
        '',
        '',
        '',
      ],
      [
        '',
        '',
        '',
        '',
        '',
        '',
      ],
      ['', '', '', '', '', ''],
      ['', '', '', '', '', ''],
    ];
    // initiate row = 13(12 + 1 header row) + number of substeps + 1

    // const initiateRow = this.initialRow + 1
    worksheet.addTable({
      name: 'ModelReferenceTable',
      ref: `A${initiateRow}`,
      columns: periodicActivitiesHeaders.map((header) => ({
        name: header.name,
      })),
      rows: periodicActivitiesBody,
    });

    worksheet.mergeCells(initiateRow, 2, initiateRow, 3);

    // Style each cell in the body
    periodicActivitiesBody.forEach((data, i) => {
      worksheet
        .getRow(initiateRow+1 + i)
        .eachCell({ includeEmpty: false }, function (cell) {
          worksheet.getCell(cell._value.model.address).style = {
            border: {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' },
            },
            alignment: { horizontal: 'left', wrapText: true, },
          };
        });
    });

    periodicActivitiesHeaders.forEach((header, index) => {
      const cell = worksheet.getCell(initiateRow, index + 1); // Headers are on row 3
      cell.style = {
        fill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'D1EFFA' },
          bgColor: { argb: 'D1EFFA' },
        },
        border: {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        },
        alignment: { horizontal: 'left', wrapText: true },
      };
    });
  }


  export function createGeneralNotesTable(worksheet, initiateRow){
    const GNHeader = worksheet.getCell(`H${initiateRow}`);
    GNHeader.value = 'General Notes';
    GNHeader.style = {
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFD9EAD3' }, // Light Green Background
      },
      alignment: { horizontal: 'left', vertical: 'top' },
      border: {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      },
    };
    worksheet.mergeCells(`H$${initiateRow}:M${initiateRow}`);

    const generalNotesCell1 = worksheet.getCell(`H${initiateRow+1}`);
    generalNotesCell1.value = '';
    generalNotesCell1.style = {
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFD9EAD3' }, // Light Blue Background
      },
      alignment: { horizontal: 'left', vertical: 'top' },
      border: {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      },
    };
    worksheet.mergeCells(`H${initiateRow+1}:H${initiateRow+1+5}`);

    const generalNotesCell2 = worksheet.getCell(`I${initiateRow+1}`);
    generalNotesCell2.value = '';
    generalNotesCell2.style = {
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFD9EAD3' }, // Light Blue Background
      },
      alignment: { horizontal: 'left', vertical: 'top' },
      border: {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      },
    };
    worksheet.mergeCells(`I${initiateRow+1}:I${initiateRow+1+5}`);

    const generalNotesCell3 = worksheet.getCell(`J${initiateRow+1}`);
    generalNotesCell3.value = '';
    generalNotesCell3.style = {
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFD9EAD3' }, // Light Blue Background
      },
      alignment: { horizontal: 'left', vertical: 'top' },
      border: {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      },
    };
    worksheet.mergeCells(`J${initiateRow+1}:M${initiateRow+1+5}`);
  }

  export function applyCellStyle(cell) {
    cell.style = {
        fill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFD8D8D8' },
          bgColor: { argb: 'FFD8D8D8' },
        },
        border: {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        },
        alignment: { horizontal: 'left', vertical: 'middle', wrapText: true },
      };
  }

  export function createCustomerProcessTable(worksheet, processes, accuracyPercent, numberOfCycles, dateOfReview, initialRow){
    let headers = [
      { name: 'Customer Process Name', width: 30 },
      { name: 'Step Name', width: 5 },
      { name: 'Step Name2', width: 25 }, // will hide this header template requirement 
      { name: 'Substep', width: 10 },
      { name: 'Customer Task Definition', width: 25 },
      { name: 'RC Task Definition', width: 30 },
      { name: 'Step Starts', width: 20 },
      { name: 'Step Ends', width: 20 },
      { name: 'Focus Camera', width: 20 },
      { name: 'Missed Steps %', width: 25 },
      { name: 'CSM Notes ', width: 15 },
      { name: 'Notes for Next Ver.', width: 15 },
      { name: 'Obj RSE Notes', width: 15 },
      { name: 'App. RSE Notes', width: 40 },
      { name: 'Smart Tools', width: 15 },
    ];

    // Gather sub-steps from processes
    let subSteps = [];
    processes[0].steps.forEach((data) => {
      subSteps.push(...data.substeps);
    });

    // Create the body for the table
    let body = [];
    subSteps.forEach((data, index) => {
      body.push([
        '',
        '',
        '',
        index + 1,
        '-',
        data,
        '-',
        '-',
        '-',
        accuracyPercent[index],
        '-',
        '-',
        '-',
        '-',
        '-',
      ]);
    });

    // Set column widths based on header configuration
    headers.forEach((header, index) => {
      worksheet.getColumn(index + 1).width = header.width; // Adjust to 1-based index
    });

    // Add a table with headers and body rows
    worksheet.addTable({
      name: 'MyTable',
      ref: 'A13',
      columns: headers.map((header) => ({ name: header.name })),
      rows: body,
    });

    // Style header row
    headers.forEach((header, index) => {
      const cell = worksheet.getCell(13, index + 1); // Headers are on row 3
      cell.style = {
        fill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'D1EFFA' },
          bgColor: { argb: 'D1EFFA' },
        },
        border: {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        },
        alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
      };
    });

    // Style each cell in the body
    subSteps.forEach((data, i) => {
      worksheet
        .getRow(14 + i)
        .eachCell({ includeEmpty: false }, function (cell) {
          worksheet.getCell(cell._value.model.address).style = {
            border: {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' },
            },
            alignment: { horizontal: cell._value.model.value == '-' ? 'center' : 'left', wrapText: true },
            ...(cell._value.model.value.toString().includes('%') &&
              parseFloat(cell._value.model.value.replace('%', '')) >= 10 && {
                fill: {
                  type: 'pattern',
                  pattern: 'solid',
                  // yellow: #FFE599
                  fgColor: {
                    argb:
                      parseFloat(cell._value.model.value.replace('%', '')) >=
                        10 &&
                      parseFloat(cell._value.model.value.replace('%', '')) <=
                        30
                        ? '#FFE599'
                        : '#FFF4CCCC',
                  },
                  bgColor: {
                    argb:
                      parseFloat(cell._value.model.value.replace('%', '')) >=
                        10 &&
                      parseFloat(cell._value.model.value.replace('%', '')) <=
                        30
                        ? '#FFE599'
                        : '#FFF4CCCC',
                  },
                  alignment: {wrapText: true}
                },
              }),
          };
        });
    });
    // setting missed steps top, J9, J10, J11 and J12
    worksheet.getCell(`J${initialRow - 5}`).value = 'No of cycles'
    worksheet.getCell(`J${initialRow - 4}`).value = numberOfCycles
    worksheet.getCell(`J${initialRow - 3}`).value = 'Date of review'
    worksheet.getCell(`J${initialRow - 2}`).value = dateOfReview

    applyCellStyle(worksheet.getCell(`J${initialRow - 5}`))
    applyCellStyle(worksheet.getCell(`J${initialRow - 4}`))
    applyCellStyle(worksheet.getCell(`J${initialRow - 3}`))
    applyCellStyle(worksheet.getCell(`J${initialRow - 2}`))

    // Cycle Steps Explanation header
    worksheet.getCell(`G${initialRow - 2}`).value = 'Cycle Steps Explanation'
    worksheet.getCell(`G${initialRow - 2}`).style = {
        fill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFD9EAD3' },
          bgColor: { argb: 'FFD9EAD3' },
        },
        border: {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        },
        alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
    }
    worksheet.mergeCells(`G${initialRow - 2}:H${initialRow - 2}`)



    // Iterate through each step to merge cells in the 'Step Name' column
    let processStartRow = initialRow;
    processes[0].steps.forEach((data, index) => {
      let startRow = processStartRow;
      const endRow = processStartRow + data.substeps.length - 1;

      // Set and style the step name in the first cell of the merged region
      worksheet.getCell(`B${startRow}`).value = index + 1;
      worksheet.getCell(`C${startRow}`).value = data.name;
      worksheet.mergeCells(startRow, 2, endRow, 2); // Merge cells from startRow to endRow in column B
      worksheet.mergeCells(startRow, 3, endRow, 3); // Merge cells from startRow to endRow in column C

      // Apply styles to the merged cell
      const cellB = worksheet.getCell(`B${startRow}`);
      cellB.style = {
        fill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'D1EFFA' },
          bgColor: { argb: 'D1EFFA' },
        },
        border: {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        },
        alignment: { horizontal: 'left', vertical: 'middle', wrapText: true },
      };
      const cellC = worksheet.getCell(`C${startRow}`);
      cellC.style = {
        fill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'D1EFFA' },
          bgColor: { argb: 'D1EFFA' },
        },
        border: {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        },
        alignment: { horizontal: 'left', vertical: 'middle', wrapText: true },
      };

      // Update the initialRow for the next iteration
      processStartRow = endRow + 1;
    });

    // Set the width of column B
    worksheet.getColumn('B').width = 5;
    worksheet.getColumn('C').width = 20;

    // merging B and C column fo this table header
    worksheet.mergeCells(13, 2, 13, 3);

    // Set the Customer Process Name in cell A4 and style it
    worksheet.getCell(`A14`).value = processes[0].name;
    worksheet.mergeCells(14, 1, 14 + subSteps.length - 1, 1); // Correct the range for column A

    // Apply styles to the merged cell
    worksheet.getCell('A14').style = {
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D1EFFA' },
        bgColor: { argb: 'D1EFFA' },
      },
      border: {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      },
      alignment: { horizontal: 'center', vertical: 'middle' },
    };

    worksheet.getColumn('A').width = 25;
  }