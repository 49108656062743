<template>
  <highcharts
    v-if="chartOptions"
    :key="isFilteredOutliers"
    :options="chartOptions"
    type="chart"
  />
</template>
<script>
export default {
  inject: ['redirectToTrace'],
  props: {
    options: {
      type: Object,
      default: () => ({}),
      required: true
    },
    selectedBinSize: {
      type: Number,
      default: 20,
      required: true
    },
    chartIndex: {
      type: Number,
      default: -1,
      required: true
    }
  },
  data() {
    return {
      chartOptions: null,
      isFilteredOutliers: false,
      data: [],
      labels: []
    };
  },

  watch: {
    selectedBinSize() {
      this.setDataAndLabels();
      this.setChartOptions();
    }
  },

  mounted() {
    if (this.options.data) this.setDataAndLabels();
    this.setChartOptions();
  },

  methods: {
    setDataAndLabels() {
      this.data = this.options.data[this.selectedBinSize][
        'bins_of_cycle_times'
      ]['freq'];
      this.labels = this.options.data[this.selectedBinSize][
        'bins_of_cycle_times'
      ]['bins'];
    },

    calculatePlotLineValue(array, plotLine) {
      var min, max;
      var minPos = -1;
      array.forEach((x, index) => {
        if (x <= plotLine) {
          min = x;
          minPos = index;
          max = array[index + 1];
        }
      });
      if (!max) return minPos;
      const difference = max - min;
      const percent = 1 / difference;
      const newPos = (plotLine - min) * percent;
      const plotLineValue = minPos + newPos;
      return plotLineValue;
    },

    setChartOptions() {
      const { xlabelString, ylabelString, plotLine } = this.options;
      const self = this;
      const { labels } = this;
      const options = {
        chart: {
          zoomType: 'xy',
          height: '70%',
          style: {
            padding: '0.5em'
          }
        },

        credits: {
          enabled: false
        },
        title: {
          text: '' //title,
        },
        legend: {
          enabled: false
        },
        xAxis: {
          categories: labels,
          labels: {
            formatter: function() {
              const { value } = this;
              return value === labels[labels.length - 1] ? `≥${value}` : value;
            }
          },
          title: {
            text: xlabelString
          }
        },
        yAxis: {
          title: {
            text: ylabelString
          }
        },
        tooltip: {
          formatter: function() {
            const { x, y } = this;
            return `<span>Cycle Time: </span><b>${x}s</b>
                <br/><span>Cycle Count: </span><b>${y}</b>`;
          }
        },

        series: [
          {
            name: 'Cycle Count',
            type: 'column',
            data: [...this.data],
            maxPointWidth: 10,
            cursor: 'pointer',
            point: {
              events: {
                click: function(e) {
                  self.redirectToTrace({
                    bin: e.point.category,
                    binSize: self.selectedBinSize,
                    isFilteredOutliers: self.isFilteredOutliers,
                    chartIndex: self.chartIndex
                  });
                }
              }
            }
          }
        ]
      };
      if (plotLine) this.setPlotLine(options);
      this.chartOptions = options;
    },

    updateDataBasedOnFilter() {
      this.data = this.options.data[this.selectedBinSize][
        'bins_of_cycle_times_without_outliers'
      ]['freq'];
    },

    setPlotLine(options) {
      const { plotLine } = this.options;
      options.xAxis['plotLines'] = [
        {
          value: this.calculatePlotLineValue(
            options.xAxis.categories,
            plotLine
          ),
          color: 'gray',
          width: 2,
          zIndex: 5,
          useHTML: true,
          label: {
            text: `Standard Cycle Time: <b>${plotLine}s</b>`,
            style: {
              color: 'black'
            }
          }
        }
      ];
    }
  }
};
</script>
