<template>
  <highcharts v-if="chartOptions" type="chart" :options="chartOptions" />
</template>
<script>
export default {
  props: {
    options: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  data() {
    return {
      chartOptions: null,
    };
  },
  mounted() {
    this.setChartOptions();
  },

  watch: {
    options: {
      handler(val) {
        this.setChartOptions();
      },
      deep: true,
    },
  },

  methods: {
    setChartOptions() {
      const {
        // title,
        data,
        background_times,
        labels,
        stepsAvgTime,
        stepsStdDev,
        xlabelString,
        ylabelString,
      } = this.options;
      this.chartOptions = {
        chart: {
          zoomType: 'xy',
          height: '70%',
          scrollablePlotArea: {
            minHeight: labels.length * 60,
            scrollPositionY: 0,
          },
          style: {
            padding: '0.5em',
          },
        },

        credits: {
          enabled: false,
        },
        title: {
          text: '', //title,
        },
        legend: {
          enabled: true,
        },
        xAxis: {
          categories: labels,
          title: {
            text: xlabelString,
          },
        },
        yAxis: {
          title: {
            text: ylabelString,
          },
        },
        tooltip: {
          formatter: function () {
            if (!this.point.custom) {
              return `<span>Mean Time: </span><b>${this.y}s</b>`;
            }
            let { std, stepExpectedTime, mean } = this.point.custom;
            if (!stepExpectedTime) stepExpectedTime = 0;
            return `<span style="color:${this.series.color}"> Average Duration: </span><b>${mean}s</b>
                <br/><span  style="color:#434348">Standard Time: </span><b>${stepExpectedTime}s</b>
                <br/><span  style="color:#F7A35C">Standard Deviation: </span><b>± ${std}</b>
                `;
          },
        },
        plotOptions: { 
          series: {
              stacking: 'normal',
              }
            },
        series: [
          {
            name: "Step Time",
            type: 'bar',
            data: data,
          },
          {
            name: "Background Time",
            type: 'bar',
            data: background_times,
          },
          {
            type: 'errorbar',
            data: stepsAvgTime,
            enableMouseTracking: false,
            whiskerLength: '100%',
            color: '#434348',
          },
          {
            type: 'errorbar',
            data: stepsStdDev,
            enableMouseTracking: false,
            whiskerLength: '100%',
            color: '#F7A35C',
          },
        ],
      };
    },

    getToolTipText(label, value, unit, color) {
      return `<span style="color:${color}"> ${label}: </span><b>${value}${unit}</b>`;
    },
  },
};
</script>
