<template>
  <a-row class="p-4">
    <a-col span="24">
      <analytics-dashboard />
    </a-col>
  </a-row>
</template>

<script>
import AnalyticsDashboard from './AnalyticsDashboard.vue';

export default {
  components: {
    AnalyticsDashboard
  }
};
</script>
