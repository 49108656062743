<template>
  <highcharts
    v-if="chartOptions"
    ref="chart"
    type="chart"
    :options="chartOptions"
  />
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  inject: ['playCycle'],
  props: {
    options: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    isFilteredOutliers: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  data() {
    return {
      chartOptions: null,
      filteredData: [],
      outLierLimit: 600,
      loading: false,
    };
  },

  computed: { ...mapGetters(['showCompareAnalytics']) },

  watch: {
    isFilteredOutliers(value) {
      const { data } = this.options;
      if (value) {
        this.filteredData = data.filter((el) => el < this.outLierLimit);
      } else {
        this.filteredData = data;
      }
      this.setChartOptions();
    },

    chartOptions() {
      const { data } = this.options;
      if (!data) return;
      this.filteredData = this.options.data.filter(
        (el) => el < this.outLierLimit
      );
    },
  },

  mounted() {
    const { data } = this.options;
    this.filteredData = data;
    this.setChartOptions();
  },

  methods: {
    setChartOptions() {
      const self = this;
      const { labels, xlabelString, ylabelString } = this.options;

      this.chartOptions = {
        chart: {
          zoomType: 'xy',
          height: this.showCompareAnalytics ? '70%' : '50%',
        },
        credits: {
          enabled: false,
        },
        title: {
          text: '',
        },
        legend: {
          enabled: true,
          symbolWidth: 10,
        },
        yAxis: {
          title: {
            text: ylabelString,
          },
        },
        xAxis: {
          categories: labels,
          title: {
            text: xlabelString,
          },
        },
        plotOptions: {
          series: {
            turboThreshold: 1000000,
            states: {
              hover: {
                enabled: true,
                lineWidth: 0.5,
              },
            },
          },
          line: {
            events: {
              legendItemClick: function () {
                return false;
              },
            },
          },
        },
        tooltip: {
          formatter: function () {
            const { x, y } = this;
            return `<span>Date: </span><b>${x}</b>
                <br/><span>Cycle Time: </span><b>${y}s</b>`;
          },
        },
        series: [
          {
            name: 'Traced Cycle',
            type: 'line',
            data: this.filteredData,
            dataGrouping: {
              enabled: true,
            },
            lineWidth: 0.8,
            label: { enabled: false },
            point: {
              events: {
                click: function () {
                  const { category: cycleIdentifier, custom } = this;
                  if (!custom?.isTraced) return;
                  self.playCycle(cycleIdentifier);
                },
              },
            },
          },
          {
            name: 'Untraced Cycle',
            color: 'lightgray',
          },
        ],
      };
    },
  },
};
</script>
